@import 'components/global.scss';

.btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: white;
  font-family: 'Saira', 'Source Sans Pro', helvetica, arial, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  padding: 6px 24px;
  border-radius: $radius;
  background-color: $c-orange;
  font-family: 'Source Sans Pro', 'sans-serif';
  transition: all 0.4s ease-in-out;
  border: transparent 2px solid;

  &:active {
    border: 2px $c-blue solid;
  }
}

.red {
  background-color: $c-red;

  &:hover {
    background-color: darken($c-red, 5%);
  }
}

.blue {
  background-color: $c-blue;

  &:hover {
    background-color: darken($c-blue, 5%);
  }
}

.teal {
  background-color: $c-teal;
  color: $c-orange;
}

.small {
  font-size: 0.9em;
}

.big {
  @media (min-width: $mobile) {
    font-size: $scale1;
  }
}

.loader {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: $radius;

  &:after {
    display: none;
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    right: 1em;
    top: 1.05em;
    z-index: 3;
    background-size: contain;
    background-image: url('./icons/ico-loader.svg');
  }

  input,
  button {
    display: block;
    width: 100% !important;
    cursor: pointer;
  }
}

.loading {
  &:after {
    // loading spinner
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    border: $c-orange 2px solid;
    border-radius: 100%;
    width: 100%;
    height: 98%;
    transform-origin: center center;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
  }
}

.outline {
  color: $c-text;
  background: transparent;
  border: 2px solid $c-grey;

  &:hover {
    background-color: darken(white, 10%);
  }
}

.text {
  cursor: pointer;
  color: $c-text;
}

.icon {
  display: inline-block;
  padding: 0;
  width: $scale1;
  height: $scale1;

  svg {
    width: 100%;
    height: 100%;
  }
}

.iconText {
  position: relative;
  padding-left: $scale4 + $scale;

  svg {
    position: absolute;
    left: $scale1;
    top: 50%;
    width: $scale1;
    height: $scale1;
    transform: translateY(-50%);
  }

  span {
    display: block;
    line-height: 0.8em;
  }
}

.alignIconRight {
  padding-left: 0;
  padding-right: $scale2;

  svg {
    left: auto;
    right: 0;
  }
}

.alignIconLeft {
  padding-left: $scale2;
  padding-right: 0;

  svg {
    left: 0;
    right: auto;
  }
}

.fullWidth {
  width: 100%;
}

.rounded {
  border-radius: $scale3;
}

.fillIcon svg {
  fill: white;
}

.dark {
  color: $c-text;
}
