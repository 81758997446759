@import 'components/global.scss';

.helper {
  padding: $scale;
  margin-bottom: $scale;
  border-radius: $radius;
  background-color: lighten($c-blue, 27%);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.icon {
  float: left;
  margin-right: $scale;
}
