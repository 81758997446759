@import 'components/global.scss';

.message {
  position: relative;
  padding: $scale3;
  overflow: hidden;
  width: 100%;
  border-radius: $radius;
  margin-bottom: $scale1;
}

.info {
  background-color: lighten($c-blue, 24%);

  h1,
  a {
    color: $c-blue;
  }

  button {
    background-color: lighten($c-blue, 8%);

    &:hover {
      background-color: darken($c-blue, 0%);
    }
  }
}

.success {
  background-color: lighten($c-green, 28%);

  h1,
  a {
    color: $c-green;
  }

  button {
    background-color: lighten($c-green, 6%);

    &:hover {
      background-color: darken($c-green, 2%);
    }
  }
}

.warning {
  background-color: lighten($c-orange, 26.5%);

  h1,
  a {
    color: $c-orange;
  }

  button {
    background-color: lighten($c-orange, 2%);

    &:hover {
      background-color: darken($c-orange, 6%);
    }
  }
}

.error {
  background-color: lighten($c-red, 32%);

  h1,
  a {
    color: lighten($c-red, 10%);
  }

  p {
    color: darken($c-text, 9%);
  }

  button {
    background-color: lighten($c-red, 10%);

    &:hover {
      background-color: $c-red;
    }
  }
}

.icon {
  float: left;
  width: 3em;
  height: 3em;
  margin-right: $scale3;

  &:after {
    width: 50%;
    height: 50%;
  }

  @media (max-width: 400px) {
    :first-child {
      float: none;
      width: $scale4;
      height: $scale4;
      margin: 0 auto;
    }
  }
}

.content {
  color: darken($c-text, 3%);
  width: auto;
  overflow: hidden;

  h1 {
    font-size: $scale1;
    margin-bottom: $scale/2;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 400px) {
    float: left;
    margin-top: $scale1;
  }
}

.close {
  position: absolute;
  top: $scale1;
  right: $scale1;
  opacity: 0.6;
  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
  margin-right: 0;
  z-index: 1;
  background-color: transparent !important;
  transition: all 0.2s ease-in-out;

  .ico {
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.9;
  }
}

.btn {
  color: white !important;
  display: inline-block;
  padding: 0.75em $scale1;
}
