@import 'components/global.scss';

.breadcrumbs {
  overflow: hidden;
  margin-bottom: $scale2;

  a {
    float: left;
    margin: 0;
    cursor: pointer;
    color: $c-text;
    text-decoration: none;

    &:hover {
      color: $c-primary;
      text-decoration: underline;
    }

    &:after {
      content: ' /';
      display: inline-block;
      color: lighten($c-text, 30%);
      margin: 0 $scale;
    }

    &:last-child:after {
      content: '';
    }
  }
}
