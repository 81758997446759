/* colours */
$c-bg: #f3f3fa;
$c-text: #707070;
$c-text-light: lighten($c-text, 20%);
$c-border: darken(white, 5%);
$c-border-dark: lighten($c-text, 52%);
$c-primary: #73b0f4;
$c-dark: #333440;
$c-light: #6e6e6e;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;

$c-red: #d95565;
$c-green: #8cc57d;
$c-blue: #8ec2ed;
$c-darkblue: #3a4488;
$c-orange: #ec4815;
$c-orange-light: #ec7b59;
$c-teal: #e6faf8;
$c-teal-light: #eaf8f7;
$c-tint: #fbfbff;
$c-purple: #6179c8;
$c-darkpurple: #556cb6;
$c-grey: #adadad;
$c-grey-dark: #1a202c;
$c-grey-light: #d6d6d6;
