@import 'components/global.scss';
@import 'components/global/animation.scss';

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);
}
