.container {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.center {
  justify-content: center;
}

.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}
