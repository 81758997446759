@import 'components/global.scss';

main {
  @media (min-width: $mobile) {
    &.with-sidebar {
      padding-left: $scale11 + $scale2;

      .blankslate-message {
        margin-left: ($scale11 + $scale2) / 2;
      }
    }
  }

  @media (max-width: $mobile + 1) {
    &.with-bottom-nav {
      padding-bottom: $scale7;
    }
  }
}

header {
  margin-bottom: $scale;
}

.col1 {
  @media (min-width: $mobile) {
    float: left;
    width: 49%;
  }
}

.col2 {
  @media (min-width: $mobile) {
    float: right;
    width: 49%;
  }
}

.title-row {
  margin-bottom: 10px;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  h2 {
    float: left;
    margin-top: 0;
  }

  button {
    float: right;
    margin-top: -0.5em;
  }
}

// Dashboard Custom CSS

.dash-stats {
  justify-content: space-evenly;
  @media (max-width: 975px) {
    flex-direction: column;
  }
}

.dash-stats section:first-child,
.dash-stats section:nth-child(2) {
  margin-right: 25px;
  @media (max-width: 975px) {
    margin-right: 0px;
  }
}

.step-stats {
  grid-template-columns: 20% 20% 20%;
  justify-content: center;
  grid-template-rows: auto;

  @media (max-width: 375px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
}

.progress-cards {
  width: 975px;

  @media (max-width: 1230px) {
    width: 100%;
  }
}

.dash-cards {
  width: 100%;
  @media (max-width: 975px) {
    margin: 0px 0px 0px 0px;
  }
}

.step-cards {
  padding-bottom: 6px;
  color: $c-light;
  @media (max-width: 975px) {
    padding-bottom: 0px;
  }
}

// Checklist Table Custom CSS

.checklist-card > table > tbody tr :nth-child(2) {
  font-family: monospace;
  font-size: larger;
  padding-right: 0;
}

.checklist-card > table > tbody tr :nth-child(3) {
  margin-left: 0;
  padding-left: 0;
  text-align: center;
}

.checklist-card > table > tbody tr :nth-child(4),
.checklist-card > table > tbody tr :nth-child(5),
.checklist-card > table > tbody tr :nth-child(6) {
  text-align: center;
  @media (max-width: $table) {
    text-align: left;
  }
}

.checklist-card th:nth-child(4),
.checklist-card th:nth-child(5),
.checklist-card th:nth-child(6) {
  text-align: center;
}

@media (max-width: $table) {
  .checklist-card td:nth-child(4):before {
    content: 'Signups: ';
  }

  .checklist-card td:nth-child(5):before {
    content: 'Finished: ';
  }

  .checklist-card td:nth-child(6):before {
    content: 'Unsubscribed: ';
  }

  .signups-card td:nth-child(5):before {
    content: 'Subscribed: ';
  }

  .manage-steps td:nth-child(2):before {
    content: 'Active: ';
  }

  .manage-steps td:nth-child(3):before {
    content: 'Checked by Default: ';
  }

  .manage-steps td:nth-child(4):before {
    content: 'Sort Order: ';
  }
}

// Signups Custom CSS

.signups-card td:nth-child(7) {
  width: $scale8;
}

.signups-card > table > tbody tr > :first-child {
  font-family: monospace;
  font-size: larger;
}

.pagination {
  margin-top: $scale5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-items {
  display: inline-block;
}

.pagination span {
  color: $c-text;
  float: left;
  padding: 4px 14px;
  margin: 0px 8px;
  text-decoration: none;
  cursor: pointer;
}

.pagination span:hover:not(.active) {
  background-color: $c-orange;
  border-radius: 5px;
  color: white;
}

.pageTotal {
  color: $c-grey-dark;
  font-size: 20px;
  padding-right: 7px;
}

.pageTotal span {
  padding: 0px;
}

.pageTotal span input {
  width: $scale3;
  margin: 0;
  color: $c-grey-dark;
  font-family: 'Saira';
  font-size: 18px;
  border: 1px $c-grey-light solid;
  border-radius: 5px;
  text-align: center;
}

.pageTotal span input::-webkit-input-placeholder {
  color: $c-grey-dark;
}

.pageTotal span:hover:not(.active) {
  background-color: white;
}

.page-select {
  margin: 12px;
}

.top-header {
  background-color: $c-orange;
  color: white;
  width: 100%;
  position: fixed;
  z-index: 4;
  height: $scale6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header-logo {
  margin-left: $scale;
}

textarea[name='emails'] {
  height: $scale14;
  overflow-y: auto;
}

// Reminders table

@media (max-width: $table) {
  .reminder-table {
    tbody {
      font-size: 18px;
    }

    tr td:nth-child(1):before {
      content: 'To: ';
      font-weight: bold;
    }

    td:nth-child(2):before {
      content: 'Checklist: ';
      font-weight: bold;
    }

    td:nth-child(3):before {
      content: 'Step: ';
      font-weight: bold;
    }

    td:nth-child(4):before {
      content: 'Sort Order: ';
      font-weight: bold;
    }

    td:nth-child(4):before {
      content: 'Date: ';
      font-weight: bold;
    }

    td:nth-child(5):before {
      content: 'Opens: ';
      font-weight: bold;
    }

    td:nth-child(6):before {
      content: 'Clicks: ';
      font-weight: bold;
    }
  }
}
