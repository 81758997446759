@import 'components/global.scss';

.label {
  display: block;
  position: relative;
  font-size: 0.85em;
  margin-bottom: 0.65em;
}

label[for='username'] {
  display: none !important;
}

.legend {
  margin-bottom: $scale1;
}

.required:after {
  content: '*';
  color: $c-red;
  margin-left: 0.35em;
  font-size: $scale;
  font-weight: 500;
}
