@import 'components/global.scss';

.richTextEditor {
  position: relative;
}

.wrapperClass {
  padding: 1rem;
  border: 1px solid #ccc;
  width: 100%;
}

.editorClass {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbarClass {
  border: 1px solid #ccc;
  color: $c-grey;
}

.toolbarClass a {
  color: $c-grey-dark;
  font-weight: 900;
}

.textbox {
  min-height: $scale13;
  display: block;
  position: relative;
  width: 100%;
  color: $c-text;
  font-size: $scale;
  padding: $scale;
  margin: 0 0 $scale1;
  border-radius: $radius;
  background-color: white;
  border: 1px solid $c-border;
  -webkit-appearance: none;
  -webkit-border-radius: $radius;

  &:focus-within {
    border: 2px solid black;
    background-color: white;
    color: $c-text;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.success {
  background-size: 0.85em;
  border-color: lighten($c-green, 24%);
  background-position: center right 1em;
}

.SuccessIcon {
  position: absolute;
  top: 50%;
  right: $scale-2;
  margin-top: $scale-1; // label height
  transform: translateY(50%);
}

.error {
  position: relative;
  color: $c-red;
  margin-bottom: 0;
  border: 1px solid $c-red;
  background-color: lighten($c-red, 38%);
}

.warning {
  background: darken($c-orange, 3%);
}

// placeholders
::-webkit-input-placeholder {
  color: lighten($c-text, 24%);
}

::-moz-placeholder {
  color: lighten($c-text, 24%);
}

:-ms-input-placeholder {
  color: lighten($c-text, 24%);
}

:-moz-placeholder {
  color: lighten($c-text, 24%);
}

.error::-webkit-input-placeholder {
  color: $c-red;
}

.error::-moz-placeholder {
  color: $c-red;
}

.error:-ms-input-placeholder {
  color: $c-red;
}

.error:-moz-placeholder {
  color: $c-red;
}
