@import 'components/global.scss';

.setup {
  padding: $scale4;

  ol {
    margin: $scale4 0 $scale2;

    li,
    a {
      color: white !important;
    }
  }

  .welcome-message {
    text-align: center;

    h1 {
      color: white;
      font-size: $scale4;
      margin-bottom: $scale/2;
    }

    @media (min-width: $mobile) {
      h1 {
        font-size: $scale6;
        margin-bottom: 0.25em;
      }
    }
  }

  @media (min-width: $mobile) {
    > div {
      max-width: $scale17;
      margin: 0 auto;
    }
  }
}
