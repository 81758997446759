@import 'components/global.scss';

.plans {
  display: block;
  margin-bottom: $scale1;
}

section.plan {
  vertical-align: top;
  margin-bottom: $scale2 !important;

  button {
    width: 100%;
    margin-top: $scale-2;
  }

  @media (max-width: $mobile) {
    margin-bottom: $scale2;

    header {
      text-align: left;
      padding-bottom: $scale-1;
      border-bottom: 1px solid $c-border;
      margin-bottom: $scale1;
    }

    ul {
      columns: 2;
      margin-left: 0;
      margin-top: $scale6;
    }

    button {
      font-size: $scale;
    }
  }

  @media (min-width: $mobile) {
    display: inline-block;
    margin-right: $scale2;
    margin-bottom: 0;
    padding-left: $scale4;
    padding-right: $scale4;

    &:last-child {
      margin-right: 0;
    }
  }
}

.name {
  font-weight: 600;
  text-align: center;

  @media (max-width: $mobile) {
    position: absolute;
    font-size: $scale1;
    color: $c-text;
  }
}

.price {
  position: relative;
  cursor: pointer;
  text-align: center;
  color: $c-text;
  font-weight: 800;

  @media (max-width: $mobile) {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: $scale3;
  }

  @media (min-width: $mobile) {
    font-size: $scale3;
    margin: 0.25em 0 $scale;
  }
}
