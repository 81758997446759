@import 'components/global.scss';

.card {
  position: relative;
  padding: $scale2;
  z-index: 1;
  margin-bottom: $scale2;
  background: white;
  border-radius: $radius;
  color: #262626;
  header {
    position: relative;
    overflow: hidden;
    text-align: center;
    color: #ec4815;
    padding-bottom: $scale1;
    margin-bottom: $scale3;
    background-color: white;
    border-bottom: 1px dotted $c-border;
    font-size: 18px;
    font-weight: bold;

    h1 {
      float: left;
      margin: 0;
      font-size: $scale1;
      font-weight: 600;
    }

    a {
      float: right;
      margin-top: 0.2em;
      font-size: 0.9em;
    }
  }

  table {
    min-height: $scale5;
    margin-top: -$scale1;
    margin-bottom: -$scale1;

    @media (max-width: $mobile) {
      margin-top: 0;
    }
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.shadow {
  box-shadow: 0em 0.15em 0.4em rgba(0, 0, 0, 0.05);
}

.loading {
  min-height: $scale13;
  text-align: center;
}

.restrictWidth {
  max-width: $scale16;
}

.noPadding {
  padding: 0;
}
