@import 'components/global.scss';

.article {
  padding: $scale3 0;

  h1 {
    color: $c-text;
    margin-bottom: $scale;
  }

  h2 {
    color: $c-text;
    font-size: $scale1;
    margin: $scale3 0 $scale 0;
  }

  h3 {
    color: $c-orange;
    font-size: $scale;
    margin-bottom: $scale;
  }

  @media (min-width: $mobile) {
    padding: $scale6 0;
  }
}
