@import 'components/global.scss';

.radio {
  float: left;
  margin: 0 $scale2 $scale-1 0;

  label {
    float: left;
    line-height: $scale2;
    font-size: $scale;
    cursor: pointer;
    text-transform: none;
  }

  input {
    position: relative;
    float: left;
    width: 2em;
    height: 2em;
    cursor: pointer;
    margin-right: $scale;
    background: none;
    border: 1px solid darken($c-border, 8%);
    background-size: 70%;
    background-position: center center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;

    &:checked {
      border: none;
      background-color: $c-green;
      transition: all 0.2s ease-in-out;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1em;
        height: 1em;
        border-radius: 100%;
        background-color: white;
        transform: translate(-50%, -50%);
      }
    }
  }
}
