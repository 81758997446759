@import 'components/global.scss';

.header {
  position: relative;
  width: auto;
  z-index: 3;
  background: white;
  color: $c-orange;
  padding: $scale2;
  margin: $scale2 * -1 $scale2 * -1 $scale2 $scale2 * -1;
  margin-left: $scale - 1;
  margin-right: $scale - 1;

  a {
    text-decoration: none;
    color: $c-text;
  }

  @media (max-width: $mobile) {
    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale;
    border: none;
  }
}

.fixed {
  position: fixed;
}
