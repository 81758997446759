@import 'components/global.scss';

.features {
  div {
    padding-right: $scale;
    margin-bottom: $scale4 !important;
  }

  div > svg {
    position: relative;
    float: left;
    margin-right: $scale-2;
  }

  h3 {
    color: $c-blue;
    text-transform: uppercase;
  }
}
