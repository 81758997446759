@import 'components/global.scss';

.table {
  width: 99%;
  thead {
    font-weight: 600;
    background-color: $c-orange;
    color: white;

    th {
      text-align: left;
      outline: none;
      cursor: pointer;
      text-transform: capitalize;
      padding: 12px;
      border-bottom: 1px dotted darken($c-border, 3%);
      &:first-child {
        padding-left: 2;
      }

      &:last-child {
        padding-right: 2;
      }
    }
  }

  tbody {
    tr {
      background-color: transparent;

      &:last-child td {
        border-bottom: 0;
      }
    }

    td {
      padding: $scale1;

      &:first-child {
        padding-left: 2;
      }

      &:last-child {
        padding-right: 2;
        max-width: 220px;
      }
    }
  }

  @media (max-width: $table) {
    thead {
      display: none;
    }

    tbody {
      tr {
        td:last-child {
          padding-bottom: $scale;
          border-bottom: 1px solid $c-border;
        }

        &:last-child td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      td {
        float: left;
        width: 100%;
        padding: 0;
        margin-bottom: $scale;
        padding-left: 15px;
      }
    }
  }

  @media (min-width: $table) {
    tbody td {
      border-bottom: 1px solid $c-border;
    }
  }
}

@media (max-width: 800px) {
  .email {
    display: none;
  }
}

@media (max-width: $table) {
  .overview {
    tbody {
      font-size: 18px;
    }

    td:nth-child(1):before {
      content: 'To: ';
      font-weight: bold;
      color: $c-orange;
    }

    td:nth-child(2):before {
      content: 'Checklist: ';
      font-weight: bold;
      color: $c-orange;
    }

    td:nth-child(3):before {
      content: 'Step: ';
      font-weight: bold;
      color: $c-orange;
    }

    td:nth-child(4):before {
      content: 'Sort Order: ';
      font-weight: bold;
      color: $c-orange;
    }

    td:nth-child(4):before {
      content: 'Date: ';
      font-weight: bold;
      color: $c-orange;
    }

    td:nth-child(5):before {
      content: 'Opens: ';
      font-weight: bold;
      color: $c-orange;
    }

    td:nth-child(6):before {
      content: 'Clicks: ';
      font-weight: bold;
      color: $c-orange;
    }
  }

  .events {
    tbody {
      font-size: 18px;
    }
    tr td:first-child {
      background: rgb(245, 245, 245);
    }

    td:nth-child(1):before {
      content: 'Type: ';
      font-weight: bold;
      color: $c-text;
    }

    td:nth-child(2):before {
      content: 'Checklist: ';
      font-weight: bold;
      color: $c-text;
    }

    td:nth-child(3):before {
      content: 'Step: ';
      font-weight: bold;
      color: $c-text;
    }

    td:nth-child(4):before {
      content: 'Sort Order: ';
      font-weight: bold;
      color: $c-text;
    }

    td:nth-child(4):before {
      content: 'Date: ';
      font-weight: bold;
      color: $c-text;
    }

    td:nth-child(5):before {
      content: 'Opens: ';
      font-weight: bold;
      color: $c-text;
    }

    td:nth-child(6):before {
      content: 'Clicks: ';
      font-weight: bold;
      color: $c-text;
    }
  }
}
